import breakpoints from "@vertikal/e-prospera.styles.breakpoints/dist/breakpoints";
import colors, { pureColors } from "@vertikal/e-prospera.styles.colors";
import Box from "@vertikal/e-prospera.ui.box";
import Text from "@vertikal/e-prospera.ui.text";
import styled from "styled-components";

export const ContentWrapper = styled.div`
  max-width: ${(props) => (props.$wide ? "100rem;" : "60rem;")};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const AccentBlock = styled(Box)`
  min-height: 250px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${(props) => {
    if (props.$color === "success") {
      return `background: ${colors.success.c200};`;
    }
    if (props.$color === "accent") {
      return `background: ${colors.accent.c900};`;
    }
    return `background: ${pureColors.white}`;
  }}
`;

export const ResidencyHeadInfo = styled.div`
  padding-top: 5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const RegisterUpsell = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media ${breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }
  gap: 3rem;
`;

export const RegisterUpsellItem = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 1.5rem;
`;

export const ResidencyTypes = styled.div`
  display: grid;
  justify-items: center;
  gap: 5rem;
  padding-bottom: 6rem;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 3px max-content;
  @media ${breakpoints.md} {
    grid-template-columns: 1fr 3px 1fr;
    grid-template-rows: auto;
  }
`;

export const ResidencyType = styled.div`
  display: flex;
  flex-direction: column;
  .material-icons-round {
    color: ${colors.success.c300};
  }
  h3 {
    color: ${pureColors.white};
  }
  p,
  li {
    color: ${colors.gray.c300};
  }
  li {
    font-size: 0.875rem;
  }
  max-width: 25rem;
  @media ${breakpoints.md} {
    padding: 0.5rem;
    max-width: unset;
  }
`;

export const BecomeResident = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${pureColors.white};
`;

export const BecomeResidentGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
  gap: 3rem;
  @media ${breakpoints.md} {
    flex-direction: row;
    gap: 1.5rem;
  }
`;

export const BecomeResidentItem = styled.div`
  color: ${pureColors.white};
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 25rem;
  p {
    color: ${colors.gray.c300};
  }
  @media ${breakpoints.md} {
    max-width: unset;
  }
`;

export const BusinessBenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  @media ${breakpoints.md} {
    gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const BusinessBenefitsItem = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 1.5rem;
  width: 100%;
`;

export const LearnMoreButton = styled.div`
  display: flex;
  a {
    width: 100%;
  }
  @media ${breakpoints.md} {
    a {
      width: auto;
    }
  }
`;

export const SuperScript = styled.sup`
  font-size: 0.875rem;
  position: relative;
  top: -0.25rem;
`;

export const Dollars = styled(Text)`
  color: ${colors.success.c400} !important;
`;

export const Advantages = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  margin-bottom: 3rem;
  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

export const BusinessBenefitsDottedDividerWrapper = styled.div`
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 6rem;
  @media ${breakpoints.md} {
    width: 33%;
  }
`;

export const HeroDottedDividerWrapper = styled.div`
  width: 66%;
  @media ${breakpoints.md} {
    width: 33%;
  }
`;

export const RegisterUpsellDividerWrapper = styled(Box)`
  width: 100%;
  @media ${breakpoints.md} {
    width: 33%;
  }
`;

import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import Colors, { pureColors } from "@vertikal/e-prospera.styles.colors";
import Text from "@vertikal/e-prospera.ui.text";
import styled from "styled-components";

export const DifferentTypesOfTaxesItem = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  span {
    color: ${Colors.success.c400};
  }
  @media ${breakpoints.md} {
    flex-direction: column;
    text-align: center;
  }
`;

export const DifferentTypesOfTaxesGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media ${breakpoints.md} {
    flex-direction: row;
    gap: 1.5rem;
  }
`;

export const DifferentTypesOfTaxes = styled.div`
  display: flex;
  flex-direction: column;
  color: ${pureColors.white};
  gap: 5rem;
  align-items: center;
  text-align: center;
`;

export const TaxHero = styled.div`
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media ${breakpoints.md} {
    gap: 4.5rem;
  }
`;

export const TaxHeroSubtitle = styled.div`
  display: flex;
  gap: 4.5rem;
  flex-direction: column-reverse;
  @media ${breakpoints.md} {
    flex-direction: row;
    align-items: center;
    & > * {
      width: 50%;
    }
  }
`;

export const DifferentTypesOfTaxesSubtitle = styled(Text)`
  color: ${Colors.gray.c300};
`;

import { Angles } from "@vertikal/e-prospera.ui.angles";
import Box from "@vertikal/e-prospera.ui.box";
import Button from "@vertikal/e-prospera.ui.button";
import ParseHtml from "@vertikal/e-prospera.ui.parse-html";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import GlobalSettings from "../components/GlobalSettings";
import MainMenu from "../components/MainMenu/MainMenu";
import { AccentBlock, ContentWrapper } from "../styles/residency.styles";
import {
  DifferentTypesOfTaxes,
  DifferentTypesOfTaxesGrid,
  DifferentTypesOfTaxesItem,
  DifferentTypesOfTaxesSubtitle,
  TaxHero,
  TaxHeroSubtitle,
} from "../styles/tax.styles";

const Tax = () => {
  const [t] = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("public.tax.title")}</title>
        <meta name="title" content={t("public.tax.meta.title")} />
        <meta name="description" content={t("public.tax.meta.description")} />
        <link rel="canonical" href="/tax" />
      </Helmet>
      <GlobalSettings />
      <MainMenu>
        <AccentBlock paddingTop={1} paddingBottom={3}>
          <ContentWrapper $wide>
            <Breadcrumbs theme="dark">
              <Link to="/">
                <Trans>common.home</Trans>
              </Link>
              <span>
                <Trans>common.taxes</Trans>
              </span>
            </Breadcrumbs>
          </ContentWrapper>
          <ContentWrapper>
            <TaxHero>
              <Box>
                <Text variant="h2" as="h1">
                  <Trans>public.tax.hero.title</Trans>
                </Text>
              </Box>
              <TaxHeroSubtitle>
                <Box>
                  <Text>
                    <ParseHtml>{t("public.tax.hero.subtitle")}</ParseHtml>
                  </Text>
                </Box>
                <Box>
                  <StaticImage
                    alt=""
                    src="../images/tax/hero.svg"
                    width={433}
                    height={303}
                  ></StaticImage>
                </Box>
              </TaxHeroSubtitle>
            </TaxHero>
          </ContentWrapper>
        </AccentBlock>
        <Angles flipVertical flipHorizontal />
        <AccentBlock paddingBottom={5} paddingTop={4} $color="accent">
          <ContentWrapper>
            <DifferentTypesOfTaxes>
              <Text variant="h3" as="h2">
                <Trans>public.tax.different_taxes.title</Trans>
              </Text>
              <DifferentTypesOfTaxesGrid>
                <DifferentTypesOfTaxesItem>
                  <Box>
                    <StaticImage
                      width={72}
                      height={72}
                      alt=""
                      src="../images/duotone-icons/id-card.svg"
                    ></StaticImage>
                  </Box>
                  <Text variant="h6" as="p">
                    <ParseHtml>
                      {t("public.tax.different_taxes.individual")}
                    </ParseHtml>
                  </Text>
                </DifferentTypesOfTaxesItem>
                <DifferentTypesOfTaxesItem>
                  <Box>
                    <StaticImage
                      width={72}
                      height={72}
                      alt=""
                      src="../images/duotone-icons/briefcase.svg"
                    ></StaticImage>
                  </Box>
                  <Text variant="h6" as="p">
                    <ParseHtml>
                      {t("public.tax.different_taxes.business")}
                    </ParseHtml>
                  </Text>
                </DifferentTypesOfTaxesItem>
                <DifferentTypesOfTaxesItem>
                  <Box>
                    <StaticImage
                      width={72}
                      height={72}
                      alt=""
                      src="../images/duotone-icons/market.svg"
                    ></StaticImage>
                  </Box>
                  <Text variant="h6" as="p">
                    <ParseHtml>
                      {t("public.tax.different_taxes.retail")}
                    </ParseHtml>
                  </Text>
                </DifferentTypesOfTaxesItem>
                <DifferentTypesOfTaxesItem>
                  <Box>
                    <StaticImage
                      width={72}
                      height={72}
                      alt=""
                      src="../images/duotone-icons/land.svg"
                    ></StaticImage>
                  </Box>
                  <Text variant="h6" as="p">
                    <ParseHtml>
                      {t("public.tax.different_taxes.land")}
                    </ParseHtml>
                  </Text>
                </DifferentTypesOfTaxesItem>
              </DifferentTypesOfTaxesGrid>
              <DifferentTypesOfTaxesSubtitle>
                <Trans>public.tax.different_taxes.subtitle</Trans>
              </DifferentTypesOfTaxesSubtitle>
            </DifferentTypesOfTaxes>
          </ContentWrapper>
        </AccentBlock>
        <Angles />
        <AccentBlock paddingBottom={3} paddingTop={6}>
          <Box
            display="flex"
            gap="1.5"
            flexDirection="column"
            alignItems="center"
          >
            <Text variant="h3" as="h2">
              <Trans>public.tax.not_a_resident_yet</Trans>
            </Text>
            <Text variant="p-lg">
              <Trans
                components={{
                  u: (
                    <Link
                      to="/residency"
                      id="learn-about-benefits"
                      className="inline-link"
                    ></Link>
                  ),
                }}
              >
                public.tax.learn_about_the_benefits
              </Trans>
            </Text>
            <Button
              color="main"
              id="not-resident-yet-cta"
              href={process.env.GATSBY_REDIRECT_URL}
            >
              <Trans>public.residency.not_resident_yet.cta</Trans>
            </Button>
          </Box>
        </AccentBlock>
      </MainMenu>
    </>
  );
};

export default Tax;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
